import styled from "@emotion/styled"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import kakaoBtn from "../../images/icon-kakao.png"
import ShareBtn from "./ShareBtn"

const KaKaoShare = props => {
  useEffect(() => {
    if (window !== undefined) {
      createKakaoButton()
    }
  }, [])

  const createKakaoButton = () => {
    // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
    if (window.Kakao) {
      const kakao = window.Kakao

      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init(process.env.GATSBY_KAKAO_KEY)
      }

      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: "#kakao-link-btn",
        objectType: "feed",
        content: {
          title: "SPARTA TECH",
          description: "팀스파르타 개발자들의 이야기",
          imageUrl:
            "https://user-images.githubusercontent.com/91445512/151499451-5e270d12-174c-4658-9ccc-d4b7c669179a.png", // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        social: {
          likeCount: 77,
          commentCount: 55,
          sharedCount: 333,
        },
        buttons: [
          {
            title: "테크 블로그 보러가기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      })
    }
  }

  return (
    <div className="kakao-share-button">
      {/* Kakao share button */}
      <ShareBtn>
        <BtnImage src={kakaoBtn} alt="kakao-share-icon" id="kakao-link-btn" />
      </ShareBtn>
    </div>
  )
}

export default KaKaoShare

const BtnImage = styled.img`
  width: 40px;
  height: 40px;
`
