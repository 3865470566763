import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "@emotion/styled"
import PostTitle from "../atoms/PostTitle"
import PostInfo from "../molecules/PostInfo"
import Layout from "./Layout"
import LinkCopy from "../atoms/LinkCopy"
import KaKaoShare from "../atoms/KaKaoShare"
import { Colors } from "./GlobalStyle"

const PostTemplate = ({ pageContext: { title } }) => {
  const {
    allNotion: { edges },
  } = useStaticQuery(postQuery)

  const {
    node: {
      childMarkdownRemark: {
        frontmatter: { author, author_detail, date, tags, title: postTitle },
        tableOfContents,
        html,
        id,
      },
      // raw: { cover },
    },
  } = edges.filter(
    edge => edge.node.childMarkdownRemark.frontmatter.title === title
  )[0]

  // 목차 만들어주는 함수
  useEffect(() => {
    for (let i = 1; i < 5; i++) {
      const hTagList = document.getElementsByTagName(`h${i}`)
      if (hTagList.length === 0) {
        continue
      }
      for (let j = 0; j < hTagList.length; j++) {
        const hTagId = hTagList[j].innerText
          .replace(".", "")
          .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            ""
          )
          .replaceAll(" ", "-")
          .replaceAll(",", "")
          .replaceAll("[", "")
          .replaceAll("]", "")
          .toLowerCase()
        hTagList[j].id = hTagId
      }
    }
  }, [])

  return (
    <Layout>
      <PostWrapper>
        <PostInfoWrapper>
          <PostTitle>{postTitle}</PostTitle>
          <TabletWrapper>
            <PostInfo
              author={author}
              author_detail={author_detail}
              date={date}
            />
            <TabletShareBtns>
              <KaKaoShare />
              <LinkCopy />
            </TabletShareBtns>
          </TabletWrapper>
        </PostInfoWrapper>
        <TOCWrapper>
          {/* {tableOfContents === "" ? null : <TOCTitle>목차</TOCTitle>} */}
          <TOCRenderer dangerouslySetInnerHTML={{ __html: tableOfContents }} />
          <ShareBtns>
            <KaKaoShare />
            <LinkCopy />
          </ShareBtns>
        </TOCWrapper>
        <ContentsRenderer dangerouslySetInnerHTML={{ __html: html }} />
      </PostWrapper>
    </Layout>
  )
}

export default PostTemplate

const PostWrapper = styled.section`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  /* font-family: "Pretendard"; */
`

const PostInfoWrapper = styled.section`
  margin-top: 60px;
  padding: 0 16px;

  ${({ theme }) => theme.tablet` 
    padding: 0 30px;
    margin-top: 100px;
  `};

  ${({ theme }) => theme.laptop` 
    width: 858px;
    padding-left: 144px;
    margin-top: 100px;
  `};
`

const TabletWrapper = styled.section`
  display: inherit;

  ${({ theme }) => theme.tablet` 
    display: flex;
    justify-content: space-between;
  `};
`

const TabletShareBtns = styled.section`
  display: none;
  ${({ theme }) => theme.tablet` 
    display: flex;
    align-items: center;
  `};
`

const TOCWrapper = styled.aside`
  /* position: fixed;
  left: 74%;
  top: 148px;
  width: 280px; */
  display: inherit;

  ${({ theme }) => theme.tablet` 
    height: 40px;
    display: none;
  `};

  ${({ theme }) => theme.laptop`
  display: flex;
    height: fit-content;
    width: 234px;
    position: fixed;
    top: 370px;
    right: 11.02%;
    padding-left: 20px;
    border-left: 2px solid #ebebeb;
  `}
`

const TOCRenderer = styled.div`
  display: none;

  ${({ theme }) => theme.laptop` 
    display: inherit;
    color: #8b8b8b;
  `};

  ol,
  ul {
    list-style: none;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;

    * + li {
      margin-top: 14px;
    }

    p {
      margin: 0;
    }

    * + ul {
      margin-top: 2px;

      li {
        margin: 2px 0 0 12px;
      }
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: gray;

    &:hover {
      color: black;
      font-weight: 500;
    }
  }
`

const ShareBtns = styled.section`
  /* display: flex;
  padding: 0 16px;
  margin-bottom: 60px; */
  display: none;

  ${({ theme }) => theme.tablet` 
    display: none;
  `};
`
export const ContentsRenderer = styled.div`
  // Renderer Style
  /* display: flex;
  flex-direction: column; */
  /* width: 894px;
  padding: 4px 0 40px 0;
  margin-left: 120px;
  word-break: break-all;

  // Markdown Style
  line-height: 1.8;
  font-size: 16px;
  font-weight: 400; */

  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  // Apply Padding Attribute to All Elements
  p {
    line-height: 1.5;
    ${({ theme }) => theme.tablet` 
      line-height: 1.7;
  `};
  }

  span[notion-color="red"] {
    color: ${Colors["red"]};
    font-weight: 600;
  }

  // Adjust Heading Element Style
  h1,
  h2 {
    font-weight: 800;
    margin-bottom: 30px;
  }

  h3 {
    font-weight: 600;
  }

  * + h1,
  * + h2,
  * + h3 {
    margin-top: 32px;

    ${({ theme }) => theme.tablet` 
      margin-top: 48px;
  `};
  }

  hr + h1,
  hr + h2,
  hr + h3 {
    margin-top: 0;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
    ${({ theme }) => theme.tablet` 
      font-size: 28px;
  `};
  }

  h3,
  h4 {
    font-size: 20px;
  }

  // Adjust Quotation Element Style
  blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 2px solid #000000;
    font-weight: 800;
  }

  // Adjust List Element Style
  ol,
  ul {
    margin: 0 0 0 20px;
    padding: 10px 0;

    li {
      margin-bottom: 5px;
    }
  }

  // Adjust Horizontal Rule style
  hr {
    border: 1px solid #000000;
    margin: 100px 0;
  }

  // Adjust Link Element Style
  a {
    text-decoration: underline;
    opacity: 0.6;
    cursor: pointer;
    color: inherit;

    &:hover {
      color: black;
    }
  }

  // Adjust Code Style
  pre[class*="language-"] {
    margin: 30px 0;
    padding: 15px;
    font-size: 15px;

    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
    }
  }

  code[class*="language-"],
  pre[class*="language-"] {
    tab-size: 2;
  }

  code[class*="language-text"] {
    color: ${Colors.red};
    padding: 3px;
  }

  code[class*="language-plaintext"] {
    padding: 3px;

    span[notion-color="red"] {
      color: ${Colors["red"]};
      font-weight: 600;
    }
  }

  span[notion-color="orange"] {
    color: ${Colors["orange"]};
    font-weight: 600;
  }

  img {
    width: 70%;
    height: auto;
    display: block;
    margin: 0px auto;
  }

  ${({ theme }) => theme.tablet` 
    padding: 0 30px;
  `};

  ${({ theme }) => theme.laptop` 
    padding: 0 439px 0 144px;
    margin-top: 84px;
  `};
`

const postQuery = graphql`
  query PostQuery {
    allNotion {
      edges {
        node {
          childMarkdownRemark {
            html
            tableOfContents
            id
            frontmatter {
              title
              summary
              author {
                id
                name
                avatar_url
              }
              author_detail
              tags {
                id
                name
                color
              }
              date {
                start(formatString: "YYYY년 MM월 DD일")
              }
            }
          }
        }
      }
    }
  }
`
