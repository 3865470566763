import React, { Children } from "react"
import styled from "@emotion/styled"

const ShareBtn = ({ children }) => {
  return <Button>{children}</Button>
}

export default ShareBtn

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 8px;
  cursor: pointer;

  ${({ theme }) => theme.tablet` 
    margin-right: 0;
    margin-left: 8px;
  `};
`
