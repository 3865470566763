import styled from "@emotion/styled"
import React from "react"
import linkBtn from "../../images/icon-linkcopy.png"
import ShareBtn from "./ShareBtn"

const LinkCopy = props => {
  const copyUrl = () => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      window.alert("복사 기능을 지원하지 않는 브라우저 입니다😂")
      return
    }
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        window.alert("링크가 복사되었습니다!")
      })
      .catch(err => {
        console.log("Something went wrong", err)
      })
  }
  return (
    <ShareBtn>
      <BtnImage src={linkBtn} alt="link_copy" onClick={copyUrl} />
    </ShareBtn>
  )
}

export default LinkCopy

const BtnImage = styled.img`
  width: 40px;
  height: 40px;
`
