import styled from "@emotion/styled"
import React from "react"

const PostTitle = ({ children }) => {
  return <Title>{children}</Title>
}

export default PostTitle

const Title = styled.h1`
  /* width: 894px; */
  width: 100%;
  margin: 0;

  ${({ theme }) => theme.tablet` 
    font-size: 32px;
  `};
`
